import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-wrapper">
              <div className="wrapper">
                <div className="footer-logo">
                  <img src="/images/logo.jpeg" alt="logo" />
                </div>
                <ul className="ps-0">
                  <li className="d-flex mt-4">
                    <span className="icon-box me-2">
                      <FaLocationDot />
                    </span>
                    <span>
                      <p className="">
                      32A/5A, L1/101, Shaheed Nagar, Tajganj, Agra, Uttar Pradesh 282001
                      </p>
                    </span>
                  </li>

                 
                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:8958484955"
                      >
                      8958484955
                      </NavLink>
                    </span>
                  </li>
                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:+918115185138"
                      >
                      8115185138
                      </NavLink>
                    </span>
                  </li>

                 
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:contact@agrarugsnhandicraft.com"
                      target="_blank"
                      rel="noopener"
                    >
                    contact@agrarugsnhandicraft.com
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:shekhar@agrarugsnhandicraft.com"
                      target="_blank"
                      rel="noopener"
                    >
                    shekhar@agrarugsnhandicraft.com
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:siddharth@agrarugsnhandicraft.com"
                      target="_blank"
                      rel="noopener"
                    >
                    siddharth@agrarugsnhandicraft.com
                    </NavLink>
                  </li>
                </ul>
                <div className="socal">
                  <p className="mb-0">
                    <strong>Follow Us</strong>
                  </p>
                  <NavLink
                    to="https://www.linkedin.com/company/103293238/admin/page-posts/published/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaLinkedin />
                  </NavLink>
                 
                  <NavLink
                    to="https://www.instagram.com/?next=%2Fexplore%2Flocations%2F264289354004067%2Fdesktop%2F&hl=en"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>
{//  <NavLink
//                     to="/"
//                     target="_blank"
//                     rel="noopener"
//                   >
//                     <FaFacebookSquare />
//                   </NavLink>
//                   <NavLink
//                     to="/"
//                     target="_blank"
//                     rel="noopener"
//                   >
//                     <FaSquareXTwitter />
//                   </NavLink>
}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
         
            <div className="wrapper">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3550.0720919571677!2d78.0348041754478!3d27.154021876504206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1733031921625!5m2!1sen!2sin"
                width="100%"
                height="390"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
     
      <div className="copyright">
        <div className="container">
          <p className="text-center mb-0">©2023-24 Agrarugs & Handicraft</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
