import React from 'react'
import { NavLink } from 'react-router-dom';
const Product = ({ProductData ,name }) => {
  return (
  
        <div className="row">
        {ProductData.data.map((item, index) => (
          <div className=" col-lg-3 col-md-4 col-sm-6 mt-4" key={index}>
          <NavLink className="text-dark" to="/contact-us">
            <div className="wrapper">
            <div className="img-wrapper">
            <img src={item.img} alt="category" />
            </div>
            <div className="text-wrapper">
            <div className="text text-center p-3">
            <h6 className=" mb-1">
            {item.name}</h6>
            <p className='quality mb-1'>{item.manufactring}</p>
           
           
            </div>
            </div>
            </div>
            </NavLink>
          </div>
          ))}
        </div>
   
  )
}

export default Product