import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Modern = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "/images/handicraft/img1.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img2.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img3.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img4.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img5.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img6.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img7.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img8.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img9.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img10.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img11.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img12.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img13.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img14.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img15.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
      {
        img: "/images/handicraft/img16.jpg",
        name: "Handicraft",
        manufactring: "hand tufted- wool",
        area: "hand tufted- wool",
        price: 475,
      },
     
    ],
  };

  const bannerData = {
    image: "/images/modern-banner.jpg",
    heading: "Handcrafted Beauty: Rugs & Carpets with Soul",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Our handcrafted rugs and carpets are more than just floor coverings; they are unique works of art created by skilled artisans. Each piece reflects the dedication and passion of the weavers, resulting in intricate designs and unparalleled quality.",
  };
  return (
    <div className="page-content">
    
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
         
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Modern;
