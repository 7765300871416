import React, { useEffect } from "react";
import "./common.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Forme from "../components/FormComponent";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";
import CourseBanner from "../components/CourseBanner";

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/contact-banner.jpg",
    heading: "Contact Us",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique atque amet consequatur facere quod iure obcaecati, nihil rerum inventore culpa incidunt tenetur, aut eos recusandae, ullam eaque est voluptatum ea veniam dolorem perspiciatis quos sit excepturi ab! Inventore id quas dignissimos eaque, tenetur ratione laboriosam excepturi sint, optio ex magni!",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>
          Contact  | Get in Touch for Showroom Design Services
        </title>
        <meta
          name="description"
          content="Discover the finest collection of handmade rugs and exquisite handicrafts at Agra Rugs & Handicraft. Our carefully crafted pieces showcase the rich heritage of Agra’s artisan craftsmanship, offering unique designs that bring warmth and elegance to your home. From luxurious woolen rugs to intricately designed handicrafts, each product is a testament to tradition and artistry."
        />
      </Helmet>
      <CourseBanner {...bannerData} />

      <div className="bg2">
        <div className="container py-5 ">
          <div className="row contact-wrapper">
            <div className="col-md-6 py-5">
              <Forme display="d-none" />
            </div>
            <div className="col-md-6 footer-info py-4">
              <div className="footer-wrapper px-4">
                <div className="wrapper">
                  <h2 className="text-white">Contact Info</h2>
                  <ul className="ps-0">
                  <li className="d-flex mt-4">
                    <span className="icon-box me-2">
                      <FaLocationDot />
                    </span>
                    <span>
                      <p className="">
                      32A/5A, L1/101, Shaheed Nagar, Tajganj, Agra, Uttar Pradesh 282001
                      </p>
                    </span>
                  </li>

                 
                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:8958484955"
                      >
                      8958484955
                      </NavLink>
                    </span>
                  </li>
                  
                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:+918115185138"
                      >
                      8115185138
                      </NavLink>
                    </span>
                  </li>
                 
                 
                 
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:contact@agrarugsnhandicraft.com"
                      target="_blank"
                      rel="noopener"
                    >
                    contact@agrarugsnhandicraft.com
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:shekhar@agrarugsnhandicraft.com"
                      target="_blank"
                      rel="noopener"
                    >
                    shekhar@agrarugsnhandicraft.com
                    </NavLink>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:siddharth@agrarugsnhandicraft.com"
                      target="_blank"
                      rel="noopener"
                    >
                    siddharth@agrarugsnhandicraft.com
                    </NavLink>
                  </li>
                </ul>
                <div className="socal">
                  <p className="mb-0">
                    <strong>Follow Us</strong>
                  </p>
                  <NavLink
                    to="https://www.linkedin.com/company/103293238/admin/page-posts/published/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaLinkedin />
                  </NavLink>
                 
                  <NavLink
                    to="https://www.instagram.com/?next=%2Fexplore%2Flocations%2F264289354004067%2Fdesktop%2F&hl=en"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
