import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";
const Header = () => {
  const [nav, setNav] = useState();
  const [tab2, setTab2] = useState();
  const navToggle = () => {
    setNav(!nav);
  };
const opendrop = () => {  
  setTab2(!tab2)
}
  const allClose = () => {
    setNav(false);
  };

  return (
    <div>
      <header>
        <div className="header position-relative">
          <div className="container">
            <nav>
              <div className="nav-left">
                <div className="logo  ">
                  <NavLink onClick={allClose} to="/">
                    <img src="/images/logo.jpeg" alt="logo" />
                  </NavLink>
                </div>
              </div>
              <div className="nav-right">
                <div className={nav ? "menu active" : "menu "}>
                  <ul className="main-ul mb-0">
                    <li>
                      <NavLink onClick={allClose} to="/">
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={allClose} to="/about-us">
                        Who we are
                      </NavLink>
                    </li>

                    <li>
                      <NavLink  onClick={opendrop}>
                        Categories <FaAngleDown />
                      </NavLink>
                      <div className={tab2 ? "drop  open" : "drop"}>
                        <div className="drop-content-wrapper">
                          <ul className="main-page-link-wrapper">
                            <li>
                              <NavLink to="/carpets-and-rugs">
                              Carpets and Rugs
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/handicraft">
                              Handicraft 
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/wall-hangings">
                              Wall Hangings
                              </NavLink>
                            </li>
                            
                            
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li>
                      <NavLink onClick={allClose} to="/contact-us">
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div className="bar">
                  <span onClick={() => navToggle()}>
                    <FaBarsStaggered />
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
